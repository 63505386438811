@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,900,900i);
body {
  max-width: 100% !important;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans';
}



.nav.navbar {
  height: 65px;
  max-width: 100% !important;
  width: 100%;
  min-height: 65px;
  overflow-x: hidden;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  background-image: none !important;
}

.navbar {
  display: flex;
  align-items: center;
  min-height: 65px;
  flex-direction: row;
  justify-content: center;
}

.inner-nav {
  min-width: 1220px;
  max-width: 1220px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.navbar-fixed-top {
  height: 65px;
  min-width: 100%;
  min-height: 65px;
  align-items: center;
  display: flex;
  max-width: 100%;
  justify-content: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.container {
  margin: 0px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  float: left;
}

.navbar-brand>a>img {
  height: 50px;
}

.navbar-nav {
  position: relative;
  display: flex;
  list-style-type: none;
  left: 0px;
  padding-left: 0px;
  min-height: 65px;
  align-items: center;
  justify-content: center;
}

ul.navstyle.nav.navbar-nav {
  display: flex;
  width: 1000px;
  height: 100%;
  align-items: center;
  list-style-type: none;
}

.navbar-nav>li>a {
  display: flex;
  font-family: "Nunito Sans";
  font-size: 16px;
  height: 65px;
  line-height: 35px !important;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #333333 !important;
}

li {
  display: flex;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  margin-left: 50px;
  letter-spacing: 0;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 65px;
  color: #333333 !important;
}

.donate-button-nav {
  float: right !important;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ffffff;
  border-width: 0px;
  border-style: none;
}

.pull-right-nav {
  float: right;
}

@media only screen and (min-width: 275px) and (max-width: 980px) {
  .navbar-default {
    height: 65px;
    width: 100%;
    background-color: white;
    max-width: 80% !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
  .donate-button-nav {
    display: none;
  }
  .navbar-nav {
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .navbar-collapse {
    background-color: white;
    overflow-y: visible;
    position: absolute;
    margin-top: 365px;
    z-index: 1;
    width: 100%;
  }
  .collapsing.navbar-collapse {
    display: none;
  }
  .navbar-brand>a>img {
    height: 40px;
    width: 130px;
  }
  .navbar-collapse ul {
    background-color: white;
    overflow: visible;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  .navbar-collapse ul li {
    background-color: white;
    overflow: hidden;
    margin-top: 7px;
    width: 100%;
    margin-left: 0;
  }
  .toggle-hamburger {
    cursor: default;
    background-color: white;
    background-image: url(/static/media/menu.51f8f52c.svg);
    box-sizing: border-box;
    padding: 2px 6px 3px;
    border-image: none;
    border-image: initial;
    margin-top: 11px;
    margin-left: 80px;
    width: 40px;
    height: 40px;
    border: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1070px) {
  .navbar-default {
    height: 60px;
    width: 100%;
    background-color: white;
    max-width: 80% !important;
  }
  .donate-button-nav {
    display: none;
  }
  .navbar-nav {
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .navbar-collapse {
    background-color: white;
    overflow-y: visible;
    position: absolute;
    z-index: 1;
    width: 100%;
  }
  .navbar-brand>a>img {
    height: 40px;
    width: 130px;
    margin-top: 11px;
  }
  .navbar-collapse ul {
    background-color: white;
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .navbar-collapse ul li {
    background-color: white;
    overflow: hidden;
    margin-top: 7px;
    width: 100%;
    margin-left: 0px;
  }
  .toggle-hamburger {
    cursor: default;
    background-color: white;
    background-image: url(/static/media/menu.51f8f52c.svg);
    box-sizing: border-box;
    padding: 2px 6px 3px;
    border-image: none;
    border-image: initial;
    margin-top: 11px;
    margin-left: 100px;
    width: 40px;
    height: 40px;
    border: none;
    display: inline;
    display: initial;
  }
}
.App {
 width: 100%;
}

button {
  border-color: none !important;
  border-style: none !important;
  border-width: 0px !important;
  padding: 1px 7px 2px;
}

.navigation {
  width: 100%;
}

.container {
  width: 0px;
  margin: 0px;
}

.footer {
  height: 450px;
  width: 100%;
}

.footer img {
  width: 272px auto;
  height: 71px;
  margin-left: 100px;
  margin-top: 30px;
  margin-right: 80%;
}

.grid-container {
  display: flex;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  margin-top: 30px;
  margin-left: 100px;
}

.grid-info {
  width: 35%;
  height: 250px;
}

.grid-info h2 {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-bottom: 22px;
}

.grid-info p {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #666666;
  margin: 0;
  margin-bottom: 22px;
}
.grid-info a {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #007ec9;
  margin-bottom: 22px;
}

.grid-contact {
  width: 15%;
  height: 250px;
}

.grid-contact h2 {
  width: 157px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-bottom: 22px;
}

.grid-contact p {
  width: 157px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #666666;
  margin: 0;
  margin-bottom: 22px;
}
.slick-next:before {
    color: #1480C6;
    opacity: 1;
    font-size: 40px;
    width: 40px;
    height: 40px;
    margin-left: -15px;
}

.slick-prev:before {
    color: #1480C6;
    opacity: 1;
    font-size: 40px;
    width: 40px;
    height: 40px;
    margin-left: -20px;
}

.slick-dots {
    margin-bottom: 20px;
    bottom: -50px;
}

@media only screen and (min-width: 275px) and (max-width: 430px){

    .App {
        width: 100%;
        overflow-x: hidden;
    }
  .footer {
    margin-top: auto;
    height: 350px;
    width: 100%;
  }

  .navigation {
    width: 100%
  }

  .grid-container {
    display: flex;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    margin-top: 30px;
    margin-left: 20px
  }

  .grid-contact {
    display: none;
  }
  .footer img {
    width: 169.2px auto;
    height: 44.2px auto;
    margin-top: 30px;
    margin-left: 20px;
  }

  .grid-info h2 {
    width: 350px;
  	height: 22px;
  	font-family: 'Nunito Sans';
  	font-size: 12px;
  	font-weight: 600;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
    margin-bottom: 10px;
  }

  .grid-info p {
    width: 350px;
  	height: 22px;
  	font-family: 'Nunito Sans';
  	font-size: 12px;
  	letter-spacing: 0;
  	text-align: left;
  	color: #666666;
    margin: 0;
    margin-bottom: 10px;
  }

    .slick-next:before {
        display: none;
    }

    .slick-prev:before {
       display: none;
    }
}



.front-header-newsItem {
  width: 100%;
  height: 458px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  object-fit: cover;
  background-position: 50% 50%;
}

.news-item-header-top{
  width: 795px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-bottom: 243px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
	font-size: 56px;
	font-weight: 600;
	line-height: 1.07;
	letter-spacing: 0;
	text-align: center;
	color: #ffffff;
	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.created-at-newsItem {
  width: 274px;
  height: 106px;
  top: 100%;
  left: 43%;

  background-color: #ffffff;
  position: absolute;
}

.created-at-newsItem img {
  width: 66px;
  height: 66px;
  margin-left: -120px;
  margin-top: 20px;
  position: absolute;
}

.created-at-name {
  width: 210px !important;
  height: 25px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin-left: 100px !important;
  margin-top: 20px;
  position: absolute;
  font-style: oblique;
  font-weight: 300 !important;
  letter-spacing: 0;
  text-align: left;
  color: #333333 !important;
}

.created-at-date {
  width: 151px;
  height: 31px;
  margin-left: 5px !important;
  margin-top: 70px;
  position: absolute;
  font-family: 'Nunito Sans' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: oblique !important;
  letter-spacing: 0;
  text-align: left;
  text-shadow: none !important;
  color: #333333 !important;
}

.content-newsItem {
  width: 800px;
  height: 100%;
  text-align: center;
  margin-top: 40px;
  white-space: pre-line;
  font-family: 'Nunito Sans';
  font-size: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #333333;
}

.content-newsItem h5 {
  margin-bottom: 25px;
  font-family: 'Nunito Sans';
	font-size: 23px;
	font-weight: 600;
	color: #007ec9;
}

.content-summary {
    font-family: 'Nunito Sans Extra-Light Italic';
    font-style: italic;
    font-weight: 300;
    font-color: 'black';
}

.bodyPicture-newsItem {
  width: 800px;
  height: 447px;
  margin-top: 50px;
}

/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {

  .front-header-newsItem-news {
    width: 100%;
	  height: 226px;
	  -webkit-filter: blur(0);
	  filter: blur(0);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: relative ;
    object-fit: cover;
    background-position: 50% 50%;

  }

  .front-header-newsItem p {
    width: 290px;
    height: 60px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: 243px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .created-at-newsItem {
    width: 99%;
    margin-top:23px;
    height: 106px;
    margin-left: -160px;
    background-color: #ffffff;
    position:absolute;
  }

  .created-at-newsItem img {
    width: 66px;
    height: 66px;
    margin-left: -140px;
    margin-top: 20px;
    position: absolute;
  }

  .created-at-date {
    width: 137px !important;
    height: 25px;
    font-family: 'Nunito Sans' !important;
    font-size: 16px !important;;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    font-style: oblique !important;
    font-weight: 300 !important;
    letter-spacing: 0;
    text-align: left;
    text-shadow: none !important;
    color: #333333;
  }

  .created-at-name {
      width: 151px;
      height: 31px;
      margin-left: 140px;
      position: absolute;
      font-family: 'Nunito Sans';
      font-size: 16px !important;;
      font-weight: 600 !important;;
      font-style: oblique;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
  }

  .content-newsItem {
    width: 320px;
    margin-left: 25px;
    text-align: center;
    margin-top: 100px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    letter-spacing: 0;
    white-space: pre-line;
    text-align: left;
    color: #333333;
  }

  .content-newsItem h5 {
      display: none;
  }

  .bodyPicture-newsItem {
    width: 320px;
    height: 447px;
    margin-top: 50px;
  }

}

.news-card {
    /* Add shadows to create the "card" effect */
    width: 30%;
    position: relative;
    text-align: left;
    color: white;
    height: 300px;
    margin-top: 2%;
    border-radius: 3px;
    cursor: pointer;
    background: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.9));
    margin-right: 0px;
    margin-left: 0px;
    overflow: hidden;
}

.news-card img {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 1;
    opacity: 0.7;
}

.news-card h4 {
    z-index: 2;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    color: #fff;
    max-width: 90%;
    font-size: 22px;
}

/* Add some padding inside the card container */
.news-card-container {
    padding: 2px 20px;
    width: 100%;
}

.news-card-title {
     margin-left: 10px;
     width: 100%;
     height: auto;
     position: absolute;
     bottom: 30px;
     font-family: 'Nunito Sans';
     font-size: 18px;
     font-weight: 600;
     letter-spacing: 0;
     text-align: left;
     color: white;
     margin-top: -35px;
}

.news-card-summary {
    width: 247px;
    height: 88px;
    margin: 0px;
    margin-top: 0px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.39;
    text-align: left;
    color: #666666;
}

.news-card-leesmeer {
    width: 200.9px;
    margin-top: 22px;
    height: 28.5px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #007ec9;
}

.arrow-news-right {
    border: solid #007ec9;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}


@media only screen and (min-width: 275px) and (max-width: 430px) {

    .news-card {
        /* Add shadows to create the "card" effect */
        width: 100%;
        border-radius: 3px;
        position: relative;
        text-align: left;
        color: white;
        height: 300px;
        margin-top: 20px;
    }

  /* On mouse-over, add a deeper shadow */
  .news-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .news-card img {
    width: 40%;
    height: 100%;
  }

  /* Add some padding inside the card container */
  .news-card-container {
    padding: 2px 10px;
    width: 100%;
  }

    .news-card-title {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 30px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-top: -35px;
    }

  .news-card-leesmeer {
    width: 120px;
    height: 22px;
    margin-top: 5px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #007ec9;
  }

  .arrow-news-right {
      border: solid #007ec9;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
  }

 }

h6 {
  width: 100%;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.more-cards-news {
  width: 800px;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.meer-container {
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {
  .meer-container {
    width: 320px;
  }
  .subheader-meer-news {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .meer-news-header {
    width: 100%;
    margin-left: 0px;
    height: 44px;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    color: #333333;
  }

  .more-cards-news {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}

.card-project {
    /* Add shadows to create the "card" effect */
  width: 320px;
  height: 470px;
    background-color: #fef4ea;
  margin-top: 70px;
  position: relative;
	box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
}

.card-project img {
  width: 100%;
  height: 223.9px;
  background: transparent;
  overflow: visible;
}

/* On mouse-over, add a deeper shadow */
.card-project:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container-card {
    padding: 2px 15px;
}

.card-title {
 margin-top: 20px;
 width: 300px;
 height: 25px;
 margin-bottom: 0px;
 font-family: 'Nunito Sans';
 font-size: 20px;
 font-weight: 600;
 letter-spacing: 0;
 text-align: left;
 color: #333333;
 display: inline-block;
}

.finished {
  width: 102px;
  height: 36px;
  border-radius: 2px;
  font-family: 'Nunito Sans';
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: center;
  padding-top: 10px;
  float: right;
	color: #ffffff;
  position: absolute;
  overflow: visible;
  background-color: #3bca69;
  margin-left: 225px;
  margin-top: 15px;
}

.card-summary {
  margin-left: 0px;
  width: 300px;
  height: 135px;
  margin-top: 5px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  letter-spacing: 0;
  padding-right: 15px;
    padding-bottom: 15px;
  text-align: left;
  color: #333333;
}

.donate-button {
  margin-top: 0;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  position: relative;
  border-width: 0px;
}
.leesmeer {
  margin-top: 0;
  cursor: pointer;
  margin-left: 130px;
  width: 121px;
  height: 22px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  padding-top: 5px;
  position: absolute;
}

.leesmeer:hover {
  color: #3b89ca;
}

.arrow-leesmeer-right {
    border: solid  #333333;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.arrow-project-right {

    display: none;

}

.linking-project {
  display: -webkit-flex; /* Safari */
 -webkit-align-items: center; /* Safari 7.0+ */
 display: flex;
 align-items: center;
 justify-content: space-between;
 position: absolute;
}

/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {

    .card-project {
        /* Add shadows to create the "card" effect */
        width: 290px;
        height: 475px;
        background-color: #fef4ea;
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
    }

    .card-project img {
        width: 100%;
        height: 223.9px;
        background: transparent;
        overflow: visible;
    }

    /* On mouse-over, add a deeper shadow */
    .card-project:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */
    .container-card {
        padding: 2px 16px;
    }

    .card-title {
        margin-top: 20px;
        width: 300px;
        height: 25px;
        margin-bottom: 0px;
        font-family: 'Nunito Sans';
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
        display: inline-block;
    }

    .finished {
        width: 102px;
        height: 36px;
        border-radius: 2px;
        font-family: 'Nunito Sans';
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        padding-top: 10px;
        float: right;
        color: #ffffff;
        position: absolute;
        overflow: visible;
        background-color: #3bca69;
        margin-left: 195px;
        margin-top: 15px;
    }

    .card-summary {
        margin-left: 0px;
        width: 280px;
        height: 135px;
        margin-top: 5px;
        font-family: 'Nunito Sans';
        font-size: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
    }

    .donate-button {
        margin-top: 0;
        width: 105px;
        height: 35px;
        border-radius: 3px;
        background-color: #f58320;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        color: #ffffff;
        margin-bottom: 19px;
        position: relative;
        border-width: 0px;
    }
    .leesmeer {
        margin-top: 0;
        cursor: pointer;
        margin-left: 130px;
        width: 121px;
        height: 22px;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
        padding-top: 5px;
        position: absolute;
    }

    .leesmeer:hover {
        color: #3b89ca;
    }

    .arrow-leesmeer-right {
        border: solid  #333333;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-project-right {

        display: none;

    }

    .linking-project {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
    }
}

.form-subscribe {
  top: 50%;
  left: 32%;
  width:500px;
  display: -webkit-flex; /* Safari */
 -webkit-align-items: center; /* Safari 7.0+ */
 display: flex;
 flex-direction: row;
 align-items: center;
 position: relative;
}

.input-mailchimp {
  width: 345px;
  height: 55px;
  border-radius: 3px;
  background-color: #ffffff;
  border-style: none;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 20px;
  color: #333333;
}

.aboneer-button {
 width: 141px;
 height: 55px;
 border-radius: 3px;
 background-color: #f58320;
 font-family: 'Nunito Sans';
 font-size: 20px;
 font-weight: 600;
 font-style: normal;
 font-stretch: normal;
 line-height: normal;
 letter-spacing: 0px;
 text-align: center;
    margin-bottom: -5px;
 color: #ffffff;
 border:none;
}

@media only screen and (min-width: 275px) and (max-width: 430px){

  input.input-mailchimp {
    width: 180px;
    height: 40px;
    border-radius: 3px;
    background-color: #ffffff;
    font-family: 'Nunito Sans';
    font-size: 20px;
      margin-left: 10px;
    border-style: none;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: left;
    color: #333333;
  }

  .aboneer-button {
    float: right;
    width: 141px;
    height: 55px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    color: #ffffff;
    margin-bottom: -5px;
    border-width: 0px;
    border-style: none;
  }

  .form-subscribe{
    top: 50%;
    left: 0%;
    width: 100px;
    display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
  }
}


.Navigation {
  height: 65px;
  z-index: 10;
  background-color: #ffffff;
}

.front-header {
  max-width: 100%;
  width: 100%;
  height: 458px;
  background:url(/static/media/front-header.5d406502.jpg) no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative;
  -webkit-transform: scale(1.00);
          transform: scale(1.00);
  overflow: hidden;
  background-position: 50% 20%;
}

.front-header p {
  width: 795px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-bottom: 243px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
	font-size: 48px;
	font-weight: 600;
	line-height: 1.07;
	letter-spacing: 0;
	text-align: center;
	color: #ffffff;
	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.subheader-projecten {
  margin-top: 20px;
  margin-left: 100px;
  width: 198px;
	height: 33px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
  color: #333333;
}

.subheader-projecten h2 {
  width: 480px;
  height: 22px;
  margin-top: 0px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #666666;
}

.cards-home {
  max-width: 1180px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.m-projects {
  padding-top: 35px;
  height: 100px;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 600;
  color: #3b89ca;
  letter-spacing: 0;
  text-align: center;
}


.m-projects:hover{
  color: #3b89ca;

}

.arrow-bekijkmeer-right {
    border: solid  #3b89ca;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.overons {
	width: 100%;
	height: 380px;
	background-color: rgba(0, 126, 201, 0.1);
  position: relative ;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.tekst-overons{
  margin-top: 30px;
  margin-left: 100px;
}

.tekst-overons h2 {
  padding-top: 20px;
  padding-left: 0px;
  margin-left: 200px;
  margin: 0;
  width: 216.9px;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.tekst-overons p {
  padding-top: 10px;
  margin-left: 0px;
	width: 456.9px;
	height: 200px;
	font-family: 'Nunito Sans';
  line-height: 1.67;
	font-size: 18px;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
}

.tekst-overons h3 {
  width: 456.9px;
  height: 200px;
  margin-top: 10px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}
.tekst-overons h3:hover {
  color: #3b89ca;
}
.overons img {
  margin:0;
  float: right;
  height: 100% auto;
  width: 50% auto;
  padding:0px;
  object-fit: cover;
}

.subheader-nieuws {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 700px;
	height: 50px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
}

.cards-news {
    width: 90%;
    margin-left: 100px;
    margin-right: 15%;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    position: relative;
}

.subheader-nieuws {
    display: flex;
    items-align: center;
}

.subheader-nieuws h2 {
  display: flex;
  margin-top: 0px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #666666;
}

.m-newsItem {
    padding-top: 35px;
    height: 100px;
    width: 100%;
    position: relative;
    text-align: center;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 20px;
    font-weight: 600;
    color: #3b89ca;
    letter-spacing: 0;
    text-align: center;
  }


.m-newsItem p:hover{
  display: none;
  color: #3b89ca;
}

.middle-header {
  width: 100%;
  height: 435px;
}

.middle-header img {
  margin:0;
  float: right;
  height: 100%;
  width: 100%;
  padding:0px;
  object-fit: cover;
}

.de-stichting{

  width: 100%;
  height: 490px;
  background-color: rgba(0, 126, 201, 0.1);
  position: relative ;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

}

.de-stichting h2 {
  width: 198px;
    height: 44px;
    margin-top: 50px;
  margin-bottom: 0px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-left: 100px;
  margin-top: 50px;
}

.de-stichting p {
    margin-top: 0px;
    width: 434px;
  	height: 390px;
  	font-family: 'Nunito Sans';
  	font-size: 18px;
  	line-height: 1.67;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
    margin-left: 100px;
}

.gallery {
    width:100%;
  display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: row;
   overflow: scroll;
   justify-content: space-between;
   align-items: center;
   position: relative;
}

.gallery-item {
    margin: 40px;
    width: 150px;
}

.gallery-item img {
    width: 75%;
    height: auto;
    margin-left: 15px;
}

.name {
  padding: 25px;
  width: 250px;
  height: 31px;
  text-align: left;
  height: 25px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
}

.desc {
    padding: 25px;
    margin-top: -15px;
    width: 200px;
  	height: 25px;
  	font-family: 'Nunito Sans';
    weight: 400;
  	font-size: 18px;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
}

.partners {
  height: 350px;
}

.partners h2 {
  margin-top: 30px;
  padding-left: 100px;
  padding-top: 34px;
  width: 400px;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.nieuwsbrief-head {
	width: 100%;
	height: 275px;
	background-color: #fef4ea;
  text-align: center;
  position: relative ;
}

.nieuwsbrief-head h8 {
  width: 750px;
  height: 41px;
  width: 795px;
  height: 120px;
  position: absolute;
  top: 25%;
  left: 32%;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.partnerbanner {
  max-width: 100%;
  width: 90%;
  padding-right: 50px;
  margin-left: 100px;
  margin-right: auto;
  display: flex;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  position: relative;
  margin-top: 80px;
}

.partnerbanner2 {
    max-width: 100%;
    width: 90%;
    padding-right: 50px;
    margin-left: 100px;
    margin-right: auto;
    display: flex;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    position: relative;
    margin-top: 80px;
}

.partner-div{
    max-width: 25%;
    width: 20%;
    heigth: 40px;
    overflow: hidden;
    position: relative;
    margin-right: 10px;
}
.hf {
  width: 100%;
  height: 40px;
  position: relative;
}

.waka-waka {
    width: 100%;
    height: 40px;
    position: relative;
}

.wildeganzen {
    width: auto;
    height: 40px;
    position: relative;
}

.jointhepipe {
    width: 100%;
    height: 40px;
    position: relative;
}
.vandebron {
    width: auto;
    padding-left: 30px;
    height: 40px;
    position: relative;
}
.sungevity {
    width: auto;
    height: 40px;
    position: relative;

}
.pureSportswear {
    width: 100%;
    height: 40px;
    position: relative;
}


/* Mobile */

@media only screen and (min-width: 500px) and (max-width: 1000px){
body {
    max-width: 100%;
    overflow-x: hidden;
}
.doneer-responsive-front{
  width: 100%;
    height: 65px;
  text-align: center;
  position: relative ;
}


.doneer-responsive-front p {
  width: 106px;
	height: 33px;
  font-family: 'Nunito Sans';
  font-size: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #f58320;
}


.front-header {
  width: 100%;
  height: 297px;
  background:url(/static/media/front-header.5d406502.jpg) no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative ;

 }

 .front-header p {
    width: 330px;
 	height: 50px;
 	font-family: 'Nunito Sans';
 	font-size: 24px;
 	font-weight: 600;
 	line-height: 1.04;
 	letter-spacing: 0;
 	text-align: center;
 	color: #ffffff;
 	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    margin-bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
 }
 .subheader-projecten {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .subheader-projecten h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }

 .cards-home {
   width: 50%;
   margin-left: 0px;
   margin-right: 10px;
    display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   position: relative;
 }

 .m-projects {
   display:none;
 }

 .overons {
 	width: 100%;
 	height: 407px;
 	background:url(/static/media/over-ons.1e0d8a19.jpeg) no-repeat center center;
  margin-top: 20px;
  position: relative ;

 }
 .tekst-overons{
   margin-left: 50px;
   display: table;
 }

 .tekst-overons h2 {
   width: 102px;
   height: 38px;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
   position: absolute;
   left: 40%;
   margin-top: 0px;
 }

 .tekst-overons p {
   width: 290px;
   height: 230px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   font-weight: 400;
   line-height: 1.67;
   letter-spacing: 0;
   font-style: normal;
  font-stretch: normal;
   margin-top: 40px;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
 }

 .tekst-overons h3 {
    width: 114px;
    height: 42px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: 'Nunito Sans';
	  font-size: 16px;
    padding-top: 12px;
	  font-weight: 600;
	  letter-spacing: 0;
	  text-align: center;
	  color: #ffffff;
    position: absolute;
    margin-top: 20px;
    margin-left: 90px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
 }
 .overons img {
    display: none;
 }

 .subheader-nieuws {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

    .cards-news {
        display:none;
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

 .subheader-nieuws h2 {
     display: none;
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }
 .m-newsItem {
    display: none;
   }
 .m-newsItem p {
  display: none;
 }

 .middle-header {
    display: none;
 }

 .middle-header img {
   margin:0;
   float: right;
   height: 100%;
   width: 100%;
   padding:0px;
   object-fit: cover;
 }

 .de-stichting{
   width: 100%;
   height: 888px;
   background-color: rgba(0, 126, 201, 0.1);
   margin-top: 25px;
 }

 .de-stichting p {
   width: 290px;
   height: 425px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   line-height: 1.56;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
   margin-left: 50px;
   margin-top: 10px;
 }

   .de-stichting h2 {
     width: 160px;
     height: 33px;
     margin-left: 40%;
     font-family: 'Nunito Sans';
     font-size: 24px;
     font-weight: 600;
     margin-top: 20px;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: 0px;
     text-align: left;
     color: #333333;
   }

   .de-stichting-tekst {
    width: 100%;
    height: 500px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
   }

 .gallery {
    margin-top: 450px;
     width:100%;
     height: 350px;
     display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
 }

 .gallery-item {
     margin: 40px;
     width: 40%;
 }

 .gallery-item:hover {
     border: 1px solid #777;
 }

 .gallery-item img {
     width: 90%;
     height: auto;
 }



.galery:hover {
    position: absolute;
    left: -300px;
}

 .name {
   padding: 25px;
   width: 100%;
   height: 31px;
   text-align: left;
   height: 40px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 700;
   letter-spacing: 0;
   color: #333333;
 }

 .desc {
    padding: 25px;
    width: 200px;
   	height: 25px;
   	font-family: 'Nunito Sans';
   	font-size: 18px;
    margin-top: 5px;
   	letter-spacing: 0;
   	text-align: left;
   	color: #333333;
 }

 .partners {
   height: 265px;
 }

 .partners h2 {
   margin: 0px;
   padding-left: 62px;
   padding-top: 34px;
   width: 400px;
   height: 44px;
   font-family: 'Nunito Sans';
   font-size: 32px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .nieuwsbrief-head {
 	  width: 100%;
 	  height: 275px;
 	  background-color: #fef4ea;
   text-align: center;
   position: absolute ;
 }



 .partnerbanner {
   width:100%;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 70px;
  margin-left: 10px;
 }

    .partner-div{
        max-width: 100%;
        width: 35%;
        heigth: 40px;
        overflow: hidden;
        position: relative;
        margin-right: 10px;
    }
    .hf {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .waka-waka {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .wildeganzen {
        width: auto;
        height: 40px;
        position: relative;
    }

    .jointhepipe {
        width: 100%;
        height: 40px;
        position: relative;
    }
    .vandebron {
        width: auto;
        padding-left: 30px;
        height: 40px;
        position: relative;
    }
    .sungevity {
        wwidth: auto;
        height: 40px;
        position: relative;

    }
    .pureSportswear {
        width: 100%;
        height: 40px;
        position: relative;
    }

 .nieuwsbrief-head h8 {
   width: 347px;
   height: 70px;
   margin-left: -150px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
 }
}


@media only screen and (min-width: 275px) and (max-width: 430px){

.doneer-responsive-front{
  width: 100%;
	height: 65px;
  text-align: center;
  position: relative ;
}


.doneer-responsive-front p {
    width: 70px;
    height: 33px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #f58320;
}

.arrow-donneer-responsive{
    margin: auto;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    width: 150px;
    height: 65px;
    display: flex;
    flex-direction: row;
}

    .doneer-responsive-front img {
        width: 34px;
        height: 33px;
        margin-left: 20px;

        line-height: 1.3px;
        letter-spacing: 0;
        text-align: center;
        color: #f58320;
    }


.front-header {
  width: 100%;
  height: 297px;
  background:url(/static/media/front-header.5d406502.jpg) no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative ;

 }

 .front-header p {
  width: 330px;
 	height: 50px;
 	font-family: 'Nunito Sans';
 	font-size: 24px;
 	font-weight: 600;
 	line-height: 1.04;
 	letter-spacing: 0;
 	text-align: center;
 	color: #ffffff;
 	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
 }
 .subheader-projecten {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .subheader-projecten h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }

 .cards-home {
   width: 100%;
   margin-left: 0px;
   margin-right: 10px;
    display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   position: relative;
 }

 .m-projects {
   display:none;
 }

 .overons {
 	width: 100%;
 	height: 407px;
     background:url(/static/media/over-ons.1e0d8a19.jpeg) no-repeat center center;
  margin-top: 20px;
  position: relative;

 }
 .tekst-overons{
   margin-left: 50px;
   display: table;
 }

 .tekst-overons h2 {
   width: 102px;
   height: 38px;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
   position: absolute;
   left: 40%;
   margin-top: 0px;
 }

 .tekst-overons p {
   width: 290px;
   height: 230px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   font-weight: 400;
   line-height: 1.67;
   letter-spacing: 0;
   font-style: normal;
  font-stretch: normal;
   margin-top: 40px;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
 }

 .tekst-overons h3 {
    width: 114px;
    height: 42px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: 'Nunito Sans';
	  font-size: 16px;
    padding-top: 12px;
	  font-weight: 600;
	  letter-spacing: 0;
	  text-align: center;
	  color: #ffffff;
    position: absolute;
    margin-top: 20px;
    margin-left: 90px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
 }
 .overons img {
    display: none;
 }

 .subheader-nieuws {

   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

    .cards-news {
        margin-left: 5%;
        width: 90%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

 .subheader-nieuws h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }
 .m-newsItem {
    display: none;
   }
 .m-newsItem p {
  display: none;
 }

 .middle-header {
    display: none;
 }

 .middle-header img {
   margin:0;
   float: right;
   height: 100%;
   width: 100%;
   padding:0px;
   object-fit: cover;
 }

 .de-stichting{
   width: 100%;
   height: 888px;
   background-color: rgba(0, 126, 201, 0.1);
   margin-top: 25px;
 }

 .de-stichting p {
   width: 290px;
   height: 425px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   line-height: 1.56;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
   margin-left: 50px;
   margin-top: 10px;
 }

   .de-stichting h2 {
     width: 160px;
     height: 33px;
     margin-left: 40%;
     font-family: 'Nunito Sans';
     font-size: 24px;
     font-weight: 600;
     margin-top: 20px;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: 0px;
     text-align: left;
     color: #333333;
   }

   .de-stichting-tekst {
    width: 100%;
    height: 500px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
   }

 .gallery {
    margin-top: 450px;
     width:100%;
     height: 350px;
     display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
 }

 .gallery-item {
     margin: 40px;
     width: 40%;
 }

 .gallery-item:hover {
     border: 1px solid #777;
 }

 .gallery-item img {
     width: 90%;
     height: auto;
 }



.galery:hover {
    position: absolute;
    left: -300px;
}

 .name {
   padding: 25px;
   width: 100%;
   height: 31px;
   text-align: left;
   height: 40px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 700;
   letter-spacing: 0;
   color: #333333;
 }

 .desc {
    padding: 25px;
    width: 200px;
   	height: 25px;
   	font-family: 'Nunito Sans';
   	font-size: 18px;
    margin-top: 5px;
   	letter-spacing: 0;
   	text-align: left;
   	color: #333333;
 }

 .partners {
   height: 330px;
 }

 .partners h2 {
   margin: 0px;
   padding-left: 62px;
   padding-top: 34px;
   width: 400px;
   height: 44px;
   font-family: 'Nunito Sans';
   font-size: 32px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .nieuwsbrief-head {
 	  width: 100%;
 	  height: 275px;
 	  background-color: #fef4ea;
   text-align: center;
   position: relative;
 }



 .partnerbanner {
   width:100%;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 70px;
  margin-left: 10px;
 }

    .partnerbanner2 {
        width:100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-direction: row;
        overflow: scroll;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 70px;
        margin-left: 10px;
    }

    .partner-div{
        max-width: 100%;
        width: 25%;
        heigth: 40px;
        position: relative;
        margin-right: 10px;
    }
    .hf {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .waka-waka {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .wildeganzen {
        width: auto;
        height: 40px;
        position: relative;
    }

    .jointhepipe {
        width: 100%;
        height: 40px;
        position: relative;
    }
    .vandebron {
        width: 100%;
        padding-left: 10px;
        height: 40px;
        position: relative;
    }
    .sungevity {
        width: 100%;
        height: 40px;
        position: relative;

    }
    .pureSportswear {
        width: 100%;
        height: 40px;
        position: relative;
    }

 .nieuwsbrief-head h8 {
   width: 347px;
   height: 70px;
   margin-left: -150px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
 }
}

.link_input__2JEBY {
  height: 34px;
  width: 220px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-color: transparent;
  border: none;
  color: #ddd;
}

.link_input__2JEBY:focus {
  outline: none;
}

.link_input__2JEBY::-webkit-input-placeholder {
  color: #aaa;
}

.link_input__2JEBY::-ms-input-placeholder {
  color: #aaa;
}

.link_input__2JEBY::placeholder {
  color: #aaa;
}

.link_inputInvalid__3EY3z {
  color: #e65757;
}

.link_link__21GPn {
  color: #2996da;
  text-decoration: underline;
}

input {

}

.input-form {
  position: relative;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  border-radius: 4px;
  border-style: none;
  background-color: #ffffff;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;

}

@media only screen and (min-width: 275px) and (max-width: 430px){
  .input-form {
    width: 270px;
  }
}

.checkoutForm {
    width: 600px;
    height: 800px;
    margin: 40px;
    background-color: #fef4ea;
}

.checkoutForm h2 {
    width: 500.4px;
    height: 27px;
    padding-top: 20px;
    padding-left: 37px;
    font-family: 'Nunito Sans';
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-align: left;
    color: #333333;
}


.submit-checkout {
  width: 218px;
	height: 49px;
	border-radius: 3px;
	background-color: #f58320;
  font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #ffffff;
  margin-top: 100px;
  margin-left: 0px;
}

.project h1 {
margin-left: 17px;
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}

.checkout-form h3 {
margin-top: 150px;
margin-left: 17px;
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}


.form-checkout {
  position: absolute;
  margin-top: 20px;
  margin-left: 37px;
}

.form-checkout h3 {
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}




.nieuwsbrief {
  width: 500px;
  height: 80px;
  margin-top: 5px;

}

.nieuwsbrief-check {
margin-top: 10px;
width: 304px;
height: 50px;
font-family: 'Nunito Sans';
font-size: 18px;
letter-spacing: 0.2px;
text-align: left;
color: #333333;
}

.checkitout {
  height: 100px;
  margin-top: -120px;
  margin-left: 65px;
}

.betaal {
  width: 280px;
  height: 40px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #333333;
}

@media only screen and (min-width: 275px) and (max-width: 430px){

  .checkoutForm {
    width: 328px;
	  height: 760px;
	  border-radius: 3px;
	  background-color: #fef4ea;
	  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
    margin-left: 25px;
    margin-top: 20px;
 }




    .submit-checkout {
      width: 218px;
    	height: 49px;
    	border-radius: 3px;
    	background-color: #f58320;
      font-family: 'Nunito Sans';
    	font-size: 16px;
    	font-weight: 600;
    	letter-spacing: 0;
    	text-align: left;
    	color: #ffffff;
      margin-top: 40px;
      margin-left: 0px;
    }

    .project h1 {
      margin-left: 17px;
      width: 409px;
      height: 54px;
      margin-top: -10px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .project-form h3 {
      margin-top: 150px;
      margin-left: 17px;
      width: 409px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .product p {
      margin-top: 70px;
      margin-left: 17px;
      width: 220px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .form-checkout {
      position: absolute;
      margin-top: 30px;
      margin-left: 37px;
    }

    .form-checkout h3 {
      width: 250px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }
    .betaal {
        width: 270px;
        height: 40px;
        border-radius: 3px;
        background-color: #f58320;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #333333;
    }


    .checkitout {
      height: 100px;
      margin-top: -130px;
      margin-left: 60px;
    }

}

.shoppingcart-checkout{
  width: 400px;
  height: 250px;
  border-radius: 3px;
  margin-left: 37px;
  margin-top: 50px;
  background-color: #ffffff;
  margin-right: 75px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
}

.winkelmandje {
  margin-top: 10px;
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.winkelmandje h3 {
   width: 400px;
   height: 25px;
   margin-left: 33px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 600;
   font-style: normal;
   font-stretch: normal;
   line-height: normal;
   letter-spacing: 0px;
   text-align: left;
   color: #333333;
   position: relative;
}


.total-checkout {
  width: 400px;
  height: 25px;
  margin-left: 33px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
}

.products-cart {
  width: 190px;
  height: 105px;
  margin-top: 10px;
  margin-left: 33px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #666666;
}

.cart-line {
  margin-top: 20px;
  width: 340px;
  height: 2px;
  margin-left: 33px;
  border: solid 1px #bcbcbc;
}

@media only screen and (min-width: 275px) and (max-width: 430px){
    .shoppingcart-checkout{
        width: 270px;
        height: 250px;
        border-radius: 3px;
        margin-left: 37px;
        margin-top: 50px;
        background-color: #ffffff;
        margin-right: 75px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    }

    .winkelmandje {
        margin-top: 10px;
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .winkelmandje h3 {
        width: 400px;
        height: 25px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        position: relative;
    }


    .total-checkout {
        width: 400px;
        height: 25px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
    }

    .products-cart {
        width: 190px;
        height: 105px;
        margin-top: 10px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #666666;
    }

    .cart-line {
        margin-top: 20px;
        width: 235px;
        height: 2px;
        margin-left: 33px;
        border: solid 1px #bcbcbc;
    }
}

