.news-card {
    /* Add shadows to create the "card" effect */
    width: 30%;
    position: relative;
    text-align: left;
    color: white;
    height: 300px;
    margin-top: 2%;
    border-radius: 3px;
    cursor: pointer;
    background: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.9));
    margin-right: 0px;
    margin-left: 0px;
    overflow: hidden;
}

.news-card img {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 1;
    opacity: 0.7;
}

.news-card h4 {
    z-index: 2;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    color: #fff;
    max-width: 90%;
    font-size: 22px;
}

/* Add some padding inside the card container */
.news-card-container {
    padding: 2px 20px;
    width: 100%;
}

.news-card-title {
     margin-left: 10px;
     width: 100%;
     height: auto;
     position: absolute;
     bottom: 30px;
     font-family: 'Nunito Sans';
     font-size: 18px;
     font-weight: 600;
     letter-spacing: 0;
     text-align: left;
     color: white;
     margin-top: -35px;
}

.news-card-summary {
    width: 247px;
    height: 88px;
    margin: 0px;
    margin-top: 0px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.39;
    text-align: left;
    color: #666666;
}

.news-card-leesmeer {
    width: 200.9px;
    margin-top: 22px;
    height: 28.5px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #007ec9;
}

.arrow-news-right {
    border: solid #007ec9;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}


@media only screen and (min-width: 275px) and (max-width: 430px) {

    .news-card {
        /* Add shadows to create the "card" effect */
        width: 100%;
        border-radius: 3px;
        position: relative;
        text-align: left;
        color: white;
        height: 300px;
        margin-top: 20px;
    }

  /* On mouse-over, add a deeper shadow */
  .news-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .news-card img {
    width: 40%;
    height: 100%;
  }

  /* Add some padding inside the card container */
  .news-card-container {
    padding: 2px 10px;
    width: 100%;
  }

    .news-card-title {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 30px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-top: -35px;
    }

  .news-card-leesmeer {
    width: 120px;
    height: 22px;
    margin-top: 5px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #007ec9;
  }

  .arrow-news-right {
      border: solid #007ec9;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
  }

 }
