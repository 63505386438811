.card-project {
    /* Add shadows to create the "card" effect */
  width: 320px;
  height: 470px;
    background-color: #fef4ea;
  margin-top: 70px;
  position: relative;
	box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
}

.card-project img {
  width: 100%;
  height: 223.9px;
  background: transparent;
  overflow: visible;
}

/* On mouse-over, add a deeper shadow */
.card-project:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container-card {
    padding: 2px 15px;
}

.card-title {
 margin-top: 20px;
 width: 300px;
 height: 25px;
 margin-bottom: 0px;
 font-family: 'Nunito Sans';
 font-size: 20px;
 font-weight: 600;
 letter-spacing: 0;
 text-align: left;
 color: #333333;
 display: inline-block;
}

.finished {
  width: 102px;
  height: 36px;
  border-radius: 2px;
  font-family: 'Nunito Sans';
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: center;
  padding-top: 10px;
  float: right;
	color: #ffffff;
  position: absolute;
  overflow: visible;
  background-color: #3bca69;
  margin-left: 225px;
  margin-top: 15px;
}

.card-summary {
  margin-left: 0px;
  width: 300px;
  height: 135px;
  margin-top: 5px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  letter-spacing: 0;
  padding-right: 15px;
    padding-bottom: 15px;
  text-align: left;
  color: #333333;
}

.donate-button {
  margin-top: 0;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  position: relative;
  border-width: 0px;
}
.leesmeer {
  margin-top: 0;
  cursor: pointer;
  margin-left: 130px;
  width: 121px;
  height: 22px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  padding-top: 5px;
  position: absolute;
}

.leesmeer:hover {
  color: #3b89ca;
}

.arrow-leesmeer-right {
    border: solid  #333333;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.arrow-project-right {

    display: none;

}

.linking-project {
  display: -webkit-flex; /* Safari */
 -webkit-align-items: center; /* Safari 7.0+ */
 display: flex;
 align-items: center;
 justify-content: space-between;
 position: absolute;
}

/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {

    .card-project {
        /* Add shadows to create the "card" effect */
        width: 290px;
        height: 475px;
        background-color: #fef4ea;
        margin-top: 50px;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
    }

    .card-project img {
        width: 100%;
        height: 223.9px;
        background: transparent;
        overflow: visible;
    }

    /* On mouse-over, add a deeper shadow */
    .card-project:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */
    .container-card {
        padding: 2px 16px;
    }

    .card-title {
        margin-top: 20px;
        width: 300px;
        height: 25px;
        margin-bottom: 0px;
        font-family: 'Nunito Sans';
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
        display: inline-block;
    }

    .finished {
        width: 102px;
        height: 36px;
        border-radius: 2px;
        font-family: 'Nunito Sans';
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        padding-top: 10px;
        float: right;
        color: #ffffff;
        position: absolute;
        overflow: visible;
        background-color: #3bca69;
        margin-left: 195px;
        margin-top: 15px;
    }

    .card-summary {
        margin-left: 0px;
        width: 280px;
        height: 135px;
        margin-top: 5px;
        font-family: 'Nunito Sans';
        font-size: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
    }

    .donate-button {
        margin-top: 0;
        width: 105px;
        height: 35px;
        border-radius: 3px;
        background-color: #f58320;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        color: #ffffff;
        margin-bottom: 19px;
        position: relative;
        border-width: 0px;
    }
    .leesmeer {
        margin-top: 0;
        cursor: pointer;
        margin-left: 130px;
        width: 121px;
        height: 22px;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
        padding-top: 5px;
        position: absolute;
    }

    .leesmeer:hover {
        color: #3b89ca;
    }

    .arrow-leesmeer-right {
        border: solid  #333333;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-project-right {

        display: none;

    }

    .linking-project {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
    }
}
