.App {
 width: 100%;
}

button {
  border-color: none !important;
  border-style: none !important;
  border-width: 0px !important;
  padding: 1px 7px 2px;
}

.navigation {
  width: 100%;
}

.container {
  width: 0px;
  margin: 0px;
}

.footer {
  height: 450px;
  width: 100%;
}

.footer img {
  width: 272px auto;
  height: 71px;
  margin-left: 100px;
  margin-top: 30px;
  margin-right: 80%;
}

.grid-container {
  display: flex;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  margin-top: 30px;
  margin-left: 100px;
}

.grid-info {
  width: 35%;
  height: 250px;
}

.grid-info h2 {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-bottom: 22px;
}

.grid-info p {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #666666;
  margin: 0;
  margin-bottom: 22px;
}
.grid-info a {
  width: 406px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #007ec9;
  margin-bottom: 22px;
}

.grid-contact {
  width: 15%;
  height: 250px;
}

.grid-contact h2 {
  width: 157px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-bottom: 22px;
}

.grid-contact p {
  width: 157px;
	height: 22px;
	font-family: 'Nunito Sans';
	font-size: 16px;
	letter-spacing: 0;
	text-align: left;
	color: #666666;
  margin: 0;
  margin-bottom: 22px;
}
.slick-next:before {
    color: #1480C6;
    opacity: 1;
    font-size: 40px;
    width: 40px;
    height: 40px;
    margin-left: -15px;
}

.slick-prev:before {
    color: #1480C6;
    opacity: 1;
    font-size: 40px;
    width: 40px;
    height: 40px;
    margin-left: -20px;
}

.slick-dots {
    margin-bottom: 20px;
    bottom: -50px;
}

@media only screen and (min-width: 275px) and (max-width: 430px){

    .App {
        width: 100%;
        overflow-x: hidden;
    }
  .footer {
    margin-top: auto;
    height: 350px;
    width: 100%;
  }

  .navigation {
    width: 100%
  }

  .grid-container {
    display: flex;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    margin-top: 30px;
    margin-left: 20px
  }

  .grid-contact {
    display: none;
  }
  .footer img {
    width: 169.2px auto;
    height: 44.2px auto;
    margin-top: 30px;
    margin-left: 20px;
  }

  .grid-info h2 {
    width: 350px;
  	height: 22px;
  	font-family: 'Nunito Sans';
  	font-size: 12px;
  	font-weight: 600;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
    margin-bottom: 10px;
  }

  .grid-info p {
    width: 350px;
  	height: 22px;
  	font-family: 'Nunito Sans';
  	font-size: 12px;
  	letter-spacing: 0;
  	text-align: left;
  	color: #666666;
    margin: 0;
    margin-bottom: 10px;
  }

    .slick-next:before {
        display: none;
    }

    .slick-prev:before {
       display: none;
    }
}


