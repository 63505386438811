input {

}

.input-form {
  position: relative;
  width: 300px;
  height: 40px;
  margin-top: 10px;
  border-radius: 4px;
  border-style: none;
  background-color: #ffffff;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;

}

@media only screen and (min-width: 275px) and (max-width: 430px){
  .input-form {
    width: 270px;
  }
}
