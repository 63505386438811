.checkoutForm {
    width: 600px;
    height: 800px;
    margin: 40px;
    background-color: #fef4ea;
}

.checkoutForm h2 {
    width: 500.4px;
    height: 27px;
    padding-top: 20px;
    padding-left: 37px;
    font-family: 'Nunito Sans';
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-align: left;
    color: #333333;
}


.submit-checkout {
  width: 218px;
	height: 49px;
	border-radius: 3px;
	background-color: #f58320;
  font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #ffffff;
  margin-top: 100px;
  margin-left: 0px;
}

.project h1 {
margin-left: 17px;
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}

.checkout-form h3 {
margin-top: 150px;
margin-left: 17px;
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}


.form-checkout {
  position: absolute;
  margin-top: 20px;
  margin-left: 37px;
}

.form-checkout h3 {
width: 409px;
height: 54px;
font-family: 'Nunito Sans';
font-size: 20px;
font-weight: 600;
letter-spacing: 0;
text-align: left;
color: #333333;
}




.nieuwsbrief {
  width: 500px;
  height: 80px;
  margin-top: 5px;

}

.nieuwsbrief-check {
margin-top: 10px;
width: 304px;
height: 50px;
font-family: 'Nunito Sans';
font-size: 18px;
letter-spacing: 0.2px;
text-align: left;
color: #333333;
}

.checkitout {
  height: 100px;
  margin-top: -120px;
  margin-left: 65px;
}

.betaal {
  width: 280px;
  height: 40px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #333333;
}

@media only screen and (min-width: 275px) and (max-width: 430px){

  .checkoutForm {
    width: 328px;
	  height: 760px;
	  border-radius: 3px;
	  background-color: #fef4ea;
	  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
    margin-left: 25px;
    margin-top: 20px;
 }




    .submit-checkout {
      width: 218px;
    	height: 49px;
    	border-radius: 3px;
    	background-color: #f58320;
      font-family: 'Nunito Sans';
    	font-size: 16px;
    	font-weight: 600;
    	letter-spacing: 0;
    	text-align: left;
    	color: #ffffff;
      margin-top: 40px;
      margin-left: 0px;
    }

    .project h1 {
      margin-left: 17px;
      width: 409px;
      height: 54px;
      margin-top: -10px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .project-form h3 {
      margin-top: 150px;
      margin-left: 17px;
      width: 409px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .product p {
      margin-top: 70px;
      margin-left: 17px;
      width: 220px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }

    .form-checkout {
      position: absolute;
      margin-top: 30px;
      margin-left: 37px;
    }

    .form-checkout h3 {
      width: 250px;
      height: 54px;
      font-family: 'Nunito Sans';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
    }
    .betaal {
        width: 270px;
        height: 40px;
        border-radius: 3px;
        background-color: #f58320;
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #333333;
    }


    .checkitout {
      height: 100px;
      margin-top: -130px;
      margin-left: 60px;
    }

}
