.shoppingcart-checkout{
  width: 400px;
  height: 250px;
  border-radius: 3px;
  margin-left: 37px;
  margin-top: 50px;
  background-color: #ffffff;
  margin-right: 75px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
}

.winkelmandje {
  margin-top: 10px;
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.winkelmandje h3 {
   width: 400px;
   height: 25px;
   margin-left: 33px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 600;
   font-style: normal;
   font-stretch: normal;
   line-height: normal;
   letter-spacing: 0px;
   text-align: left;
   color: #333333;
   position: relative;
}


.total-checkout {
  width: 400px;
  height: 25px;
  margin-left: 33px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
}

.products-cart {
  width: 190px;
  height: 105px;
  margin-top: 10px;
  margin-left: 33px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #666666;
}

.cart-line {
  margin-top: 20px;
  width: 340px;
  height: 2px;
  margin-left: 33px;
  border: solid 1px #bcbcbc;
}

@media only screen and (min-width: 275px) and (max-width: 430px){
    .shoppingcart-checkout{
        width: 270px;
        height: 250px;
        border-radius: 3px;
        margin-left: 37px;
        margin-top: 50px;
        background-color: #ffffff;
        margin-right: 75px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    }

    .winkelmandje {
        margin-top: 10px;
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .winkelmandje h3 {
        width: 400px;
        height: 25px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
        position: relative;
    }


    .total-checkout {
        width: 400px;
        height: 25px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: left;
        color: #333333;
    }

    .products-cart {
        width: 190px;
        height: 105px;
        margin-top: 10px;
        margin-left: 33px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #666666;
    }

    .cart-line {
        margin-top: 20px;
        width: 235px;
        height: 2px;
        margin-left: 33px;
        border: solid 1px #bcbcbc;
    }
}
