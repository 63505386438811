
.Navigation {
  height: 65px;
  z-index: 10;
  background-color: #ffffff;
}

.front-header {
  max-width: 100%;
  width: 100%;
  height: 458px;
  background:url('../img/front-header.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative;
  transform: scale(1.00);
  overflow: hidden;
  background-position: 50% 20%;
}

.front-header p {
  width: 795px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-bottom: 243px;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
	font-size: 48px;
	font-weight: 600;
	line-height: 1.07;
	letter-spacing: 0;
	text-align: center;
	color: #ffffff;
	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.subheader-projecten {
  margin-top: 20px;
  margin-left: 100px;
  width: 198px;
	height: 33px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
  color: #333333;
}

.subheader-projecten h2 {
  width: 480px;
  height: 22px;
  margin-top: 0px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #666666;
}

.cards-home {
  max-width: 1180px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.m-projects {
  padding-top: 35px;
  height: 100px;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 600;
  color: #3b89ca;
  letter-spacing: 0;
  text-align: center;
}


.m-projects:hover{
  color: #3b89ca;

}

.arrow-bekijkmeer-right {
    border: solid  #3b89ca;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.overons {
	width: 100%;
	height: 380px;
	background-color: rgba(0, 126, 201, 0.1);
  position: relative ;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.tekst-overons{
  margin-top: 30px;
  margin-left: 100px;
}

.tekst-overons h2 {
  padding-top: 20px;
  padding-left: 0px;
  margin-left: 200px;
  margin: 0;
  width: 216.9px;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.tekst-overons p {
  padding-top: 10px;
  margin-left: 0px;
	width: 456.9px;
	height: 200px;
	font-family: 'Nunito Sans';
  line-height: 1.67;
	font-size: 18px;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
}

.tekst-overons h3 {
  width: 456.9px;
  height: 200px;
  margin-top: 10px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}
.tekst-overons h3:hover {
  color: #3b89ca;
}
.overons img {
  margin:0;
  float: right;
  height: 100% auto;
  width: 50% auto;
  padding:0px;
  object-fit: cover;
}

.subheader-nieuws {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 700px;
	height: 50px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
}

.cards-news {
    width: 90%;
    margin-left: 100px;
    margin-right: 15%;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    position: relative;
}

.subheader-nieuws {
    display: flex;
    items-align: center;
}

.subheader-nieuws h2 {
  display: flex;
  margin-top: 0px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #666666;
}

.m-newsItem {
    padding-top: 35px;
    height: 100px;
    width: 100%;
    position: relative;
    text-align: center;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 20px;
    font-weight: 600;
    color: #3b89ca;
    letter-spacing: 0;
    text-align: center;
  }


.m-newsItem p:hover{
  display: none;
  color: #3b89ca;
}

.middle-header {
  width: 100%;
  height: 435px;
}

.middle-header img {
  margin:0;
  float: right;
  height: 100%;
  width: 100%;
  padding:0px;
  object-fit: cover;
}

.de-stichting{

  width: 100%;
  height: 490px;
  background-color: rgba(0, 126, 201, 0.1);
  position: relative ;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

}

.de-stichting h2 {
  width: 198px;
    height: 44px;
    margin-top: 50px;
  margin-bottom: 0px;
	font-family: 'Nunito Sans';
	font-size: 32px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: left;
	color: #333333;
  margin-left: 100px;
  margin-top: 50px;
}

.de-stichting p {
    margin-top: 0px;
    width: 434px;
  	height: 390px;
  	font-family: 'Nunito Sans';
  	font-size: 18px;
  	line-height: 1.67;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
    margin-left: 100px;
}

.gallery {
    width:100%;
  display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: row;
   overflow: scroll;
   justify-content: space-between;
   align-items: center;
   position: relative;
}

.gallery-item {
    margin: 40px;
    width: 150px;
}

.gallery-item img {
    width: 75%;
    height: auto;
    margin-left: 15px;
}

.name {
  padding: 25px;
  width: 250px;
  height: 31px;
  text-align: left;
  height: 25px;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
}

.desc {
    padding: 25px;
    margin-top: -15px;
    width: 200px;
  	height: 25px;
  	font-family: 'Nunito Sans';
    weight: 400;
  	font-size: 18px;
  	letter-spacing: 0;
  	text-align: left;
  	color: #333333;
}

.partners {
  height: 350px;
}

.partners h2 {
  margin-top: 30px;
  padding-left: 100px;
  padding-top: 34px;
  width: 400px;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.nieuwsbrief-head {
	width: 100%;
	height: 275px;
	background-color: #fef4ea;
  text-align: center;
  position: relative ;
}

.nieuwsbrief-head h8 {
  width: 750px;
  height: 41px;
  width: 795px;
  height: 120px;
  position: absolute;
  top: 25%;
  left: 32%;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.partnerbanner {
  max-width: 100%;
  width: 90%;
  padding-right: 50px;
  margin-left: 100px;
  margin-right: auto;
  display: flex;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  position: relative;
  margin-top: 80px;
}

.partnerbanner2 {
    max-width: 100%;
    width: 90%;
    padding-right: 50px;
    margin-left: 100px;
    margin-right: auto;
    display: flex;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    position: relative;
    margin-top: 80px;
}

.partner-div{
    max-width: 25%;
    width: 20%;
    heigth: 40px;
    overflow: hidden;
    position: relative;
    margin-right: 10px;
}
.hf {
  width: 100%;
  height: 40px;
  position: relative;
}

.waka-waka {
    width: 100%;
    height: 40px;
    position: relative;
}

.wildeganzen {
    width: auto;
    height: 40px;
    position: relative;
}

.jointhepipe {
    width: 100%;
    height: 40px;
    position: relative;
}
.vandebron {
    width: auto;
    padding-left: 30px;
    height: 40px;
    position: relative;
}
.sungevity {
    width: auto;
    height: 40px;
    position: relative;

}
.pureSportswear {
    width: 100%;
    height: 40px;
    position: relative;
}


/* Mobile */

@media only screen and (min-width: 500px) and (max-width: 1000px){
body {
    max-width: 100%;
    overflow-x: hidden;
}
.doneer-responsive-front{
  width: 100%;
    height: 65px;
  text-align: center;
  position: relative ;
}


.doneer-responsive-front p {
  width: 106px;
	height: 33px;
  font-family: 'Nunito Sans';
  font-size: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #f58320;
}


.front-header {
  width: 100%;
  height: 297px;
  background:url('../img/front-header.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative ;

 }

 .front-header p {
    width: 330px;
 	height: 50px;
 	font-family: 'Nunito Sans';
 	font-size: 24px;
 	font-weight: 600;
 	line-height: 1.04;
 	letter-spacing: 0;
 	text-align: center;
 	color: #ffffff;
 	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    margin-bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 .subheader-projecten {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .subheader-projecten h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }

 .cards-home {
   width: 50%;
   margin-left: 0px;
   margin-right: 10px;
    display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   position: relative;
 }

 .m-projects {
   display:none;
 }

 .overons {
 	width: 100%;
 	height: 407px;
 	background:url('../img/over-ons.jpeg') no-repeat center center;
  margin-top: 20px;
  position: relative ;

 }
 .tekst-overons{
   margin-left: 50px;
   display: table;
 }

 .tekst-overons h2 {
   width: 102px;
   height: 38px;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
   position: absolute;
   left: 40%;
   margin-top: 0px;
 }

 .tekst-overons p {
   width: 290px;
   height: 230px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   font-weight: 400;
   line-height: 1.67;
   letter-spacing: 0;
   font-style: normal;
  font-stretch: normal;
   margin-top: 40px;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
 }

 .tekst-overons h3 {
    width: 114px;
    height: 42px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: 'Nunito Sans';
	  font-size: 16px;
    padding-top: 12px;
	  font-weight: 600;
	  letter-spacing: 0;
	  text-align: center;
	  color: #ffffff;
    position: absolute;
    margin-top: 20px;
    margin-left: 90px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
 }
 .overons img {
    display: none;
 }

 .subheader-nieuws {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

    .cards-news {
        display:none;
        width: 100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

 .subheader-nieuws h2 {
     display: none;
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }
 .m-newsItem {
    display: none;
   }
 .m-newsItem p {
  display: none;
 }

 .middle-header {
    display: none;
 }

 .middle-header img {
   margin:0;
   float: right;
   height: 100%;
   width: 100%;
   padding:0px;
   object-fit: cover;
 }

 .de-stichting{
   width: 100%;
   height: 888px;
   background-color: rgba(0, 126, 201, 0.1);
   margin-top: 25px;
 }

 .de-stichting p {
   width: 290px;
   height: 425px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   line-height: 1.56;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
   margin-left: 50px;
   margin-top: 10px;
 }

   .de-stichting h2 {
     width: 160px;
     height: 33px;
     margin-left: 40%;
     font-family: 'Nunito Sans';
     font-size: 24px;
     font-weight: 600;
     margin-top: 20px;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: 0px;
     text-align: left;
     color: #333333;
   }

   .de-stichting-tekst {
    width: 100%;
    height: 500px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
   }

 .gallery {
    margin-top: 450px;
     width:100%;
     height: 350px;
     display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
 }

 .gallery-item {
     margin: 40px;
     width: 40%;
 }

 .gallery-item:hover {
     border: 1px solid #777;
 }

 .gallery-item img {
     width: 90%;
     height: auto;
 }



.galery:hover {
    position: absolute;
    left: -300px;
}

 .name {
   padding: 25px;
   width: 100%;
   height: 31px;
   text-align: left;
   height: 40px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 700;
   letter-spacing: 0;
   color: #333333;
 }

 .desc {
    padding: 25px;
    width: 200px;
   	height: 25px;
   	font-family: 'Nunito Sans';
   	font-size: 18px;
    margin-top: 5px;
   	letter-spacing: 0;
   	text-align: left;
   	color: #333333;
 }

 .partners {
   height: 265px;
 }

 .partners h2 {
   margin: 0px;
   padding-left: 62px;
   padding-top: 34px;
   width: 400px;
   height: 44px;
   font-family: 'Nunito Sans';
   font-size: 32px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .nieuwsbrief-head {
 	  width: 100%;
 	  height: 275px;
 	  background-color: #fef4ea;
   text-align: center;
   position: absolute ;
 }



 .partnerbanner {
   width:100%;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 70px;
  margin-left: 10px;
 }

    .partner-div{
        max-width: 100%;
        width: 35%;
        heigth: 40px;
        overflow: hidden;
        position: relative;
        margin-right: 10px;
    }
    .hf {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .waka-waka {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .wildeganzen {
        width: auto;
        height: 40px;
        position: relative;
    }

    .jointhepipe {
        width: 100%;
        height: 40px;
        position: relative;
    }
    .vandebron {
        width: auto;
        padding-left: 30px;
        height: 40px;
        position: relative;
    }
    .sungevity {
        wwidth: auto;
        height: 40px;
        position: relative;

    }
    .pureSportswear {
        width: 100%;
        height: 40px;
        position: relative;
    }

 .nieuwsbrief-head h8 {
   width: 347px;
   height: 70px;
   margin-left: -150px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
 }
}


@media only screen and (min-width: 275px) and (max-width: 430px){

.doneer-responsive-front{
  width: 100%;
	height: 65px;
  text-align: center;
  position: relative ;
}


.doneer-responsive-front p {
    width: 70px;
    height: 33px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #f58320;
}

.arrow-donneer-responsive{
    margin: auto;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    width: 150px;
    height: 65px;
    display: flex;
    flex-direction: row;
}

    .doneer-responsive-front img {
        width: 34px;
        height: 33px;
        margin-left: 20px;

        line-height: 1.3px;
        letter-spacing: 0;
        text-align: center;
        color: #f58320;
    }


.front-header {
  width: 100%;
  height: 297px;
  background:url('../img/front-header.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative ;

 }

 .front-header p {
  width: 330px;
 	height: 50px;
 	font-family: 'Nunito Sans';
 	font-size: 24px;
 	font-weight: 600;
 	line-height: 1.04;
 	letter-spacing: 0;
 	text-align: center;
 	color: #ffffff;
 	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
 }
 .subheader-projecten {
   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .subheader-projecten h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }

 .cards-home {
   width: 100%;
   margin-left: 0px;
   margin-right: 10px;
    display: -webkit-flex; /* Safari */
   -webkit-align-items: center; /* Safari 7.0+ */
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   position: relative;
 }

 .m-projects {
   display:none;
 }

 .overons {
 	width: 100%;
 	height: 407px;
     background:url('../img/over-ons.jpeg') no-repeat center center;
  margin-top: 20px;
  position: relative;

 }
 .tekst-overons{
   margin-left: 50px;
   display: table;
 }

 .tekst-overons h2 {
   width: 102px;
   height: 38px;
   font-family: 'Nunito Sans';
   font-size: 20px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
   position: absolute;
   left: 40%;
   margin-top: 0px;
 }

 .tekst-overons p {
   width: 290px;
   height: 230px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   font-weight: 400;
   line-height: 1.67;
   letter-spacing: 0;
   font-style: normal;
  font-stretch: normal;
   margin-top: 40px;
   text-align: center;
   color: #ffffff;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
 }

 .tekst-overons h3 {
    width: 114px;
    height: 42px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: 'Nunito Sans';
	  font-size: 16px;
    padding-top: 12px;
	  font-weight: 600;
	  letter-spacing: 0;
	  text-align: center;
	  color: #ffffff;
    position: absolute;
    margin-top: 20px;
    margin-left: 90px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
 }
 .overons img {
    display: none;
 }

 .subheader-nieuws {

   width: 198px;
   height: 33px;
   margin: 0;
   margin-top: 20px;
   margin-left: 40%;
   margin-bottom: 50px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

    .cards-news {
        margin-left: 5%;
        width: 90%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

 .subheader-nieuws h2 {
    margin-left: -50%;
    width: 300px;
    height: 49px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;

 }
 .m-newsItem {
    display: none;
   }
 .m-newsItem p {
  display: none;
 }

 .middle-header {
    display: none;
 }

 .middle-header img {
   margin:0;
   float: right;
   height: 100%;
   width: 100%;
   padding:0px;
   object-fit: cover;
 }

 .de-stichting{
   width: 100%;
   height: 888px;
   background-color: rgba(0, 126, 201, 0.1);
   margin-top: 25px;
 }

 .de-stichting p {
   width: 290px;
   height: 425px;
   font-family: 'Nunito Sans';
   font-size: 16px;
   line-height: 1.56;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
   margin-left: 50px;
   margin-top: 10px;
 }

   .de-stichting h2 {
     width: 160px;
     height: 33px;
     margin-left: 40%;
     font-family: 'Nunito Sans';
     font-size: 24px;
     font-weight: 600;
     margin-top: 20px;
     font-style: normal;
     font-stretch: normal;
     line-height: normal;
     letter-spacing: 0px;
     text-align: left;
     color: #333333;
   }

   .de-stichting-tekst {
    width: 100%;
    height: 500px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
   }

 .gallery {
    margin-top: 450px;
     width:100%;
     height: 350px;
     display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
 }

 .gallery-item {
     margin: 40px;
     width: 40%;
 }

 .gallery-item:hover {
     border: 1px solid #777;
 }

 .gallery-item img {
     width: 90%;
     height: auto;
 }



.galery:hover {
    position: absolute;
    left: -300px;
}

 .name {
   padding: 25px;
   width: 100%;
   height: 31px;
   text-align: left;
   height: 40px;
   font-family: 'Nunito Sans';
   font-size: 18px;
   font-weight: 700;
   letter-spacing: 0;
   color: #333333;
 }

 .desc {
    padding: 25px;
    width: 200px;
   	height: 25px;
   	font-family: 'Nunito Sans';
   	font-size: 18px;
    margin-top: 5px;
   	letter-spacing: 0;
   	text-align: left;
   	color: #333333;
 }

 .partners {
   height: 330px;
 }

 .partners h2 {
   margin: 0px;
   padding-left: 62px;
   padding-top: 34px;
   width: 400px;
   height: 44px;
   font-family: 'Nunito Sans';
   font-size: 32px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: left;
   color: #333333;
 }

 .nieuwsbrief-head {
 	  width: 100%;
 	  height: 275px;
 	  background-color: #fef4ea;
   text-align: center;
   position: relative;
 }



 .partnerbanner {
   width:100%;
   display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 70px;
  margin-left: 10px;
 }

    .partnerbanner2 {
        width:100%;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        flex-direction: row;
        overflow: scroll;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 70px;
        margin-left: 10px;
    }

    .partner-div{
        max-width: 100%;
        width: 25%;
        heigth: 40px;
        position: relative;
        margin-right: 10px;
    }
    .hf {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .waka-waka {
        width: 100%;
        height: 40px;
        position: relative;
    }

    .wildeganzen {
        width: auto;
        height: 40px;
        position: relative;
    }

    .jointhepipe {
        width: 100%;
        height: 40px;
        position: relative;
    }
    .vandebron {
        width: 100%;
        padding-left: 10px;
        height: 40px;
        position: relative;
    }
    .sungevity {
        width: 100%;
        height: 40px;
        position: relative;

    }
    .pureSportswear {
        width: 100%;
        height: 40px;
        position: relative;
    }

 .nieuwsbrief-head h8 {
   width: 347px;
   height: 70px;
   margin-left: -150px;
   font-family: 'Nunito Sans';
   font-size: 24px;
   font-weight: 600;
   letter-spacing: 0;
   text-align: center;
   color: #333333;
 }
}
