.nav.navbar {
  height: 65px;
  max-width: 100% !important;
  width: 100%;
  min-height: 65px;
  overflow-x: hidden;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  background-image: none !important;
}

.navbar {
  display: flex;
  align-items: center;
  min-height: 65px;
  flex-direction: row;
  justify-content: center;
}

.inner-nav {
  min-width: 1220px;
  max-width: 1220px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.navbar-fixed-top {
  height: 65px;
  min-width: 100%;
  min-height: 65px;
  align-items: center;
  display: flex;
  max-width: 100%;
  justify-content: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.container {
  margin: 0px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  float: left;
}

.navbar-brand>a>img {
  height: 50px;
}

.navbar-nav {
  position: relative;
  display: flex;
  list-style-type: none;
  left: 0px;
  padding-left: 0px;
  min-height: 65px;
  align-items: center;
  justify-content: center;
}

ul.navstyle.nav.navbar-nav {
  display: flex;
  width: 1000px;
  height: 100%;
  align-items: center;
  list-style-type: none;
}

.navbar-nav>li>a {
  display: flex;
  font-family: "Nunito Sans";
  font-size: 16px;
  height: 65px;
  line-height: 35px !important;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #333333 !important;
}

li {
  display: flex;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  margin-left: 50px;
  letter-spacing: 0;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 65px;
  color: #333333 !important;
}

.donate-button-nav {
  float: right !important;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ffffff;
  border-width: 0px;
  border-style: none;
}

.pull-right-nav {
  float: right;
}

@media only screen and (min-width: 275px) and (max-width: 980px) {
  .navbar-default {
    height: 65px;
    width: 100%;
    background-color: white;
    max-width: 80% !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
  .donate-button-nav {
    display: none;
  }
  .navbar-nav {
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .navbar-collapse {
    background-color: white;
    overflow-y: visible;
    position: absolute;
    margin-top: 365px;
    z-index: 1;
    width: 100%;
  }
  .collapsing.navbar-collapse {
    display: none;
  }
  .navbar-brand>a>img {
    height: 40px;
    width: 130px;
  }
  .navbar-collapse ul {
    background-color: white;
    overflow: visible;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  .navbar-collapse ul li {
    background-color: white;
    overflow: hidden;
    margin-top: 7px;
    width: 100%;
    margin-left: 0;
  }
  .toggle-hamburger {
    cursor: default;
    background-color: white;
    background-image: url("../img/menu.svg");
    box-sizing: border-box;
    padding: 2px 6px 3px;
    border-image: initial;
    margin-top: 11px;
    margin-left: 80px;
    width: 40px;
    height: 40px;
    border: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1070px) {
  .navbar-default {
    height: 60px;
    width: 100%;
    background-color: white;
    max-width: 80% !important;
  }
  .donate-button-nav {
    display: none;
  }
  .navbar-nav {
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .navbar-collapse {
    background-color: white;
    overflow-y: visible;
    position: absolute;
    z-index: 1;
    width: 100%;
  }
  .navbar-brand>a>img {
    height: 40px;
    width: 130px;
    margin-top: 11px;
  }
  .navbar-collapse ul {
    background-color: white;
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .navbar-collapse ul li {
    background-color: white;
    overflow: hidden;
    margin-top: 7px;
    width: 100%;
    margin-left: 0px;
  }
  .toggle-hamburger {
    cursor: default;
    background-color: white;
    background-image: url("../img/menu.svg");
    box-sizing: border-box;
    padding: 2px 6px 3px;
    border-image: initial;
    margin-top: 11px;
    margin-left: 100px;
    width: 40px;
    height: 40px;
    border: none;
    display: initial;
  }
}