.front-header-newsItem {
  width: 100%;
  height: 458px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  object-fit: cover;
  background-position: 50% 50%;
}

.news-item-header-top{
  width: 795px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  margin-bottom: 243px;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
	font-size: 56px;
	font-weight: 600;
	line-height: 1.07;
	letter-spacing: 0;
	text-align: center;
	color: #ffffff;
	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.created-at-newsItem {
  width: 274px;
  height: 106px;
  top: 100%;
  left: 43%;

  background-color: #ffffff;
  position: absolute;
}

.created-at-newsItem img {
  width: 66px;
  height: 66px;
  margin-left: -120px;
  margin-top: 20px;
  position: absolute;
}

.created-at-name {
  width: 210px !important;
  height: 25px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin-left: 100px !important;
  margin-top: 20px;
  position: absolute;
  font-style: oblique;
  font-weight: 300 !important;
  letter-spacing: 0;
  text-align: left;
  color: #333333 !important;
}

.created-at-date {
  width: 151px;
  height: 31px;
  margin-left: 5px !important;
  margin-top: 70px;
  position: absolute;
  font-family: 'Nunito Sans' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: oblique !important;
  letter-spacing: 0;
  text-align: left;
  text-shadow: none !important;
  color: #333333 !important;
}

.content-newsItem {
  width: 800px;
  height: 100%;
  text-align: center;
  margin-top: 40px;
  white-space: pre-line;
  font-family: 'Nunito Sans';
  font-size: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #333333;
}

.content-newsItem h5 {
  margin-bottom: 25px;
  font-family: 'Nunito Sans';
	font-size: 23px;
	font-weight: 600;
	color: #007ec9;
}

.content-summary {
    font-family: 'Nunito Sans Extra-Light Italic';
    font-style: italic;
    font-weight: 300;
    font-color: 'black';
}

.bodyPicture-newsItem {
  width: 800px;
  height: 447px;
  margin-top: 50px;
}

/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {

  .front-header-newsItem-news {
    width: 100%;
	  height: 226px;
	  -webkit-filter: blur(0);
	  filter: blur(0);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: relative ;
    object-fit: cover;
    background-position: 50% 50%;

  }

  .front-header-newsItem p {
    width: 290px;
    height: 60px;
    font-family: 'Nunito Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: 243px;
    transform: translate(-50%, -50%);
  }

  .created-at-newsItem {
    width: 99%;
    margin-top:23px;
    height: 106px;
    margin-left: -160px;
    background-color: #ffffff;
    position:absolute;
  }

  .created-at-newsItem img {
    width: 66px;
    height: 66px;
    margin-left: -140px;
    margin-top: 20px;
    position: absolute;
  }

  .created-at-date {
    width: 137px !important;
    height: 25px;
    font-family: 'Nunito Sans' !important;
    font-size: 16px !important;;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    font-style: oblique !important;
    font-weight: 300 !important;
    letter-spacing: 0;
    text-align: left;
    text-shadow: none !important;
    color: #333333;
  }

  .created-at-name {
      width: 151px;
      height: 31px;
      margin-left: 140px;
      position: absolute;
      font-family: 'Nunito Sans';
      font-size: 16px !important;;
      font-weight: 600 !important;;
      font-style: oblique;
      letter-spacing: 0;
      text-align: left;
      color: #333333;
  }

  .content-newsItem {
    width: 320px;
    margin-left: 25px;
    text-align: center;
    margin-top: 100px;
    font-family: 'Nunito Sans';
    font-size: 18px;
    letter-spacing: 0;
    white-space: pre-line;
    text-align: left;
    color: #333333;
  }

  .content-newsItem h5 {
      display: none;
  }

  .bodyPicture-newsItem {
    width: 320px;
    height: 447px;
    margin-top: 50px;
  }

}
