h6 {
  width: 100%;
  height: 44px;
  font-family: 'Nunito Sans';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
}

.more-cards-news {
  width: 800px;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.meer-container {
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
/* Mobile */
@media only screen and (min-width: 275px) and (max-width: 430px) {
  .meer-container {
    width: 320px;
  }
  .subheader-meer-news {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .meer-news-header {
    width: 100%;
    margin-left: 0px;
    height: 44px;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    color: #333333;
  }

  .more-cards-news {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
}
